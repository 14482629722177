import { Component, OnInit } from '@angular/core';
import { LocalCacheService } from 'src/app/service/cache/local-cache.service';
declare var $:any;

@Component({
  selector: 'app-vendor-sidebar',
  templateUrl: './vendor-sidebar.component.html',
  styleUrls: ['./vendor-sidebar.component.scss']
})
export class VendorSidebarComponent implements OnInit {

  anprPublic: boolean;
  anprResidential: boolean;
  rfidResidential: boolean;
  rfidCorporate: boolean;
  club: boolean;
  ois: boolean;
  
  constructor(public forage: LocalCacheService) { }

  ngOnInit() {
    localStorage.removeItem('visitedEditSpotCom');

    this.forage.getItem('isShowVendorNavMenu').then(response => {
      if(response){
        this.anprPublic = response['ANPRPublic'];
        this.anprResidential = response['ANPRResidential'];
        this.rfidResidential = response['RFIDResidential'];
        this.rfidCorporate = response['RFIDCorporate'];
        this.club = response['club'];
        this.ois = response['ois'];
      }
    });

  }

  close(){
    $("body").removeClass("sidebar-enable");
  }

}
