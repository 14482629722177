import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "src/app/core/services/cookie.service";
import { LocalCacheService } from "src/app/service/cache/local-cache.service";
import { ConstantsService } from "src/app/service/constant-service";
import { CommonServiceService } from "../../service/common-service.service";
declare var $: any;

@Component({
  selector: "app-residential-vendor-topbar",
  templateUrl: "./residential-vendor-topbar.component.html",
  styleUrls: ["./residential-vendor-topbar.component.scss"],
})
export class ResidentialVendorTopbarComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() isDropdown: boolean;
  @Input() titletop: string;
  spot: any;
  dash: boolean;
  currentLocation: string;
  spotid: any;
  spotname: any;

  constructor(
    public commonService: CommonServiceService,
    public constantsService: ConstantsService,
    private router: Router,
    private apiservice: CommonServiceService,
    private forage: LocalCacheService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.spotId();
    this.currentLocation = window.location.pathname;
    this.spotid = this.currentLocation.split("/")[2];
    this.forage.getItem("FullName_Vendor").then((res) => {
      if (res) {
        this.commonService.FullName_Vendor = res;
      } else {
        this.commonService.FullName_Vendor = undefined;
      }
    });
  }

  /* This function will get spot */

  spotId() {
    var data = "";
    this.apiservice
      .getRequest(this.constantsService.PARKINGSPOT + "?dropdown=true", data)
      .then((res) => {
        this.spot = res["data"];
        if (this.spotid) {
          this.spot.forEach((element) => {
            if (element.id == this.spotid) {
              this.spotname = element.parking_sport_name;
            }
          });
        }
      });
  }

  /* This function will logout */

  logout() {
    this.commonService.headerUpdate$.next(false);
    this.cookieService.deleteCookie("currentUser");
    this.forage.clearCache();
    this.router.navigate([""]);
  }

  /* This function will open sidebar */

  open() {
    if ($(window).width() < 767) {
      $("body").toggleClass("sidebar-enable");
    } else if ($(window).width() > 767) {
      $("body").toggleClass("enlarged");
    }
  }

  /* This function will redirect to vendor dashboard */

  getiddashboard(id, name) {
    if (id != "") {
      try {
        window.location.href = "/vendor-dashboard/" + id;
      } catch (error) {}
    } else {
      alert("No spot found");
    }
  }
}
