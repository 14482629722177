import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { FakeBackendProvider } from "./core/helpers/fake-backend";
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ToastrModule } from "ngx-toastr";
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { NgxPaginationModule } from "ngx-pagination";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { ReactiveFormsModule } from "@angular/forms";
import { ResidentialEditSpotsAdminComponent } from "./residential_admin/parking-spots/residential-edit-spots-admin/residential-edit-spots-admin.component";
import { ResidentialAddSpotsAdminComponent } from "./residential_admin/parking-spots/residential-add-spots-admin/residential-add-spots-admin.component";
import { ResidentialViewSpotsAdminComponent } from "./residential_admin/parking-spots/residential-view-spots-admin/residential-view-spots-admin.component";

// new module added

import { ResidentialEditSpotsAdminANPRComponent } from "./admin_anpr_resident/parking-spots/residential-edit-spots-admin/residential-edit-spots-admin.component";
import { ResidentialAddSpotsAdminANPRComponent } from "./admin_anpr_resident/parking-spots/residential-add-spots-admin/residential-add-spots-admin.component";
import { ResidentialViewSpotsAdminANPRComponent } from "./admin_anpr_resident/parking-spots/residential-view-spots-admin/residential-view-spots-admin.component";

import { ResidentialEditSpotsAdminRFIDComponent } from "./admin_rfid_corporate/parking-spots/residential-edit-spots-admin/residential-edit-spots-admin.component";
import { ResidentialAddSpotsAdminRFIDComponent } from "./admin_rfid_corporate/parking-spots/residential-add-spots-admin/residential-add-spots-admin.component";
import { ResidentialViewSpotsAdminRFIDComponent } from "./admin_rfid_corporate/parking-spots/residential-view-spots-admin/residential-view-spots-admin.component";

import { SharedModule } from "./shared/shared.module";
import { NgxFloatingImgModule } from "ngx-floating-img";

@NgModule({
  declarations: [
    AppComponent,
    ResidentialEditSpotsAdminComponent,
    ResidentialAddSpotsAdminComponent,
    ResidentialViewSpotsAdminComponent,

    // new module added

    ResidentialEditSpotsAdminANPRComponent,
    ResidentialAddSpotsAdminANPRComponent,
    ResidentialViewSpotsAdminANPRComponent,

    ResidentialEditSpotsAdminRFIDComponent,
    ResidentialAddSpotsAdminRFIDComponent,
    ResidentialViewSpotsAdminRFIDComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    RichTextEditorAllModule,
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({ timeOut: 2000, maxOpened: 1 }),
    NgxFloatingImgModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    FakeBackendProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
