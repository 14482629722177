import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Router } from "@angular/router";
import { EventService } from "../../core/services/event.service";
import { LocalCacheService } from "../../service/cache/local-cache.service";
import { CommonServiceService } from "../../service/common-service.service";
import { CommonFunctions } from "../../common.function";
import { CookieService } from "src/app/core/services/cookie.service";
declare var $: any;

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  pageHeading: string;
  notificationItems: Array<{}>;
  openMobileMenu: boolean;
  public name: any;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  @Input("topbarName") set topbarName(value) {
    this.name = value;
  }

  constructor(
    private router: Router,
    public commonService: CommonServiceService,
    private eventService: EventService,
    private forage: LocalCacheService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    var routeLink = this.router.url;
    this._fetchNotifications();
    this.openMobileMenu = false;
    this.forage.getItem("user").then((res) => {
      if (res) {
        this.commonService.user = res;
        this.commonService.user.full_name;
      } else {
        this.commonService.user = undefined;
      }
    });
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    //this.authService.logout();
    this.forage.clearCache();
    this.cookieService.deleteCookie("currentUser");
    this.router.navigate([""]);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = [
      {
        text: "Caleb Flakelar commented on Admin",
        subText: "1 min ago",
        icon: "mdi mdi-comment-account-outline",
        bgColor: "primary",
        redirectTo: "/notification/1",
      },
      {
        text: "New user registered.",
        subText: "5 min ago",
        icon: "mdi mdi-account-plus",
        bgColor: "info",
        redirectTo: "/notification/2",
      },
      {
        text: "Cristina Pride",
        subText: "Hi, How are you? What about our next meeting",
        icon: "mdi mdi-comment-account-outline",
        bgColor: "success",
        redirectTo: "/notification/3",
      },
      {
        text: "Caleb Flakelar commented on Admin",
        subText: "2 days ago",
        icon: "mdi mdi-comment-account-outline",
        bgColor: "danger",
        redirectTo: "/notification/4",
      },
      {
        text: "Caleb Flakelar commented on Admin",
        subText: "1 min ago",
        icon: "mdi mdi-comment-account-outline",
        bgColor: "primary",
        redirectTo: "/notification/5",
      },
      {
        text: "New user registered.",
        subText: "5 min ago",
        icon: "mdi mdi-account-plus",
        bgColor: "info",
        redirectTo: "/notification/6",
      },
      {
        text: "Cristina Pride",
        subText: "Hi, How are you? What about our next meeting",
        icon: "mdi mdi-comment-account-outline",
        bgColor: "success",
        redirectTo: "/notification/7",
      },
      {
        text: "Caleb Flakelar commented on Admin",
        subText: "2 days ago",
        icon: "mdi mdi-comment-account-outline",
        bgColor: "danger",
        redirectTo: "/notification/8",
      },
    ];
  }

  open() {
    if ($(window).width() < 767) {
      $("body").toggleClass("sidebar-enable");
    } else if ($(window).width() > 767) {
      $("body").toggleClass("enlarged");
    }
  }
}
