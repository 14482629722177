import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalCacheService } from './cache/local-cache.service';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class CommonServiceService {

  updateProduct(data: { activeVariant: any; id: any; }, httpOptions: (data: { activeVariant: any; id: any; }, httpOptions: any) => void) {
    throw new Error('Method not implemented.');
  }
  public title: any = ''
  public adminTitle: any;
  public httpOptions: any;
  public fileupload: any;
  public FullName_Vendor: any;
  public FullName_Admin: any;
  httpotpions: { headers: HttpHeaders; };

  isLoggedin: boolean = false;
  isVendor: boolean = false
  isAdmin: boolean = false

  public headerUpdate$ = new Subject<boolean>();
  public isVendorUpdate$ = new Subject<boolean>();
  public isAdminUpdate$ = new Subject<boolean>();


  // Test Server URL
  // public domain = "https://test-api.parkit.biz/v1/";

  // Live Server URL
  public domain = "https://api.parkit.biz/v1/"

  user: any;

  constructor(
    private http: HttpClient,
    public forage: LocalCacheService,
    public router: Router,
  ) {
    this.headerUpdate$.subscribe(res => this.isLoggedin = res);
    this.isVendorUpdate$.subscribe(res => this.isVendor = res);
    this.isAdminUpdate$.subscribe(res => this.isAdmin = res);
  }

  getHeader(type) {
    return this.forage.getItem('auth_token').then(res => {
      if (!type) {
        return true;
      }
      if (res) {
        if (type == 'all') {
          return this.httpotpions = {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              "Authorization": "Bearer " + res,
            })
          };
        } else if (type == 'token') {
          return this.httpotpions = {
            headers: new HttpHeaders({
              "Authorization": "Bearer " + res,
            })
          };
        }
      }
    });
  }

  getRequest(api, data, head = "token") {
    return new Promise((resolve, reject) => {
      this.getHeader(head).then((dbres) => {
        if (dbres) {
          this.http.get<any>(this.domain + api + data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
        else {
          this.http.get<any>(this.domain + api + data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }

      });
    });
  }

  postRequest(api, data, header = "token") {
    return new Promise((resolve, reject) => {
      this.getHeader(header).then((dbres) => {
        if (dbres) {
          this.http.post<any>(this.domain + api, data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.post<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }


      });
    });
  }

  putRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.getHeader('all').then((dbres) => {
        if (dbres) {
          this.http.put<any>(this.domain + api, data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.put<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }


      });
    });
  }

  patchRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.getHeader('all').then((dbres) => {
        if (dbres) {
          this.http.patch<any>(this.domain + api, data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.patch<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }


      });
    });
  }

  patchRequestWithFile(api, data, header = "token") {
    return new Promise((resolve, reject) => {
      this.getHeader(header).then((dbres) => {
        if (dbres) {
          this.http.patch<any>(this.domain + api, data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        } else {
          this.http.patch<any>(this.domain + api, data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }


      });
    });
  }

  deleteRequest(api, data) {
    return new Promise((resolve, reject) => {
      this.getHeader('token').then((dbres) => {
        if (dbres) {
          this.http.delete<any>(this.domain + api + data, this.httpotpions).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }
        else {
          this.http.delete<any>(this.domain + api + data).subscribe(res => {
            //if condition data validation
            resolve(res);
          }, error => {
            reject(error);
          })
        }

      });
    });
  }

  autoLogout(currentUrl: string, isActive = true) {
    this.forage.getItem('user').then((dbres) => {
      if (isActive) {
        if (dbres) {
          if (currentUrl == '/' || currentUrl == '/login') {
            this.router.navigate(['dashboard/view-dashboard']);
          } else {
            this.router.navigate([currentUrl]);
          }
        }
        else {
          this.router.navigate(['']);
        }
      } else {
        if (dbres) {
          this.router.navigate(['dashboard/view-dashboard']);
        }

      }
    });
  }

  // TABLE SORTING FUNCTION START
  sortDataByCol(array, column, type, dataType) {
    if (array.length > 0) {
      if (typeof column === "object") {
        if (dataType == 'int') {
          if (type == 'asc') {
            array = array.sort((a, b) => {
              return a[column[0]][column[1]] - b[column[0]][column[1]]
            });
          } else if (type == 'des') {
            array = array.sort((a, b) => {
              return b[column[0]][column[1]] - a[column[0]][column[1]]
            });
          }
        } else {
          if (type == 'asc') {
            array = array.sort((a, b) => {
              return (a[column[0]][column[1]] == b[column[0]][column[1]]) ? 0 : ((a[column[0]][column[1]] > b[column[0]][column[1]]) ? 1 : -1);
            });
          } else if (type == 'des') {
            array = array.sort((a, b) => {
              return (b[column[0]][column[1]] == a[column[0]][column[1]]) ? 0 : ((b[column[0]][column[1]] > a[column[0]][column[1]]) ? 1 : -1);
            });
          }
        }
      }
      else {
        if (dataType == 'int') {
          if (type == 'asc') {
            array = array.sort((a, b) => {
              return a[column] - b[column]
            });
          } else if (type == 'des') {
            array = array.sort((a, b) => {
              return b[column] - a[column]
            });
          }
        } else {
          if (type == 'asc') {
            array = array.sort((a, b) => {
              return (a[column] == b[column]) ? 0 : ((a[column] > b[column]) ? 1 : -1);
            });
          } else if (type == 'des') {
            array = array.sort((a, b) => {
              return (b[column] == a[column]) ? 0 : ((b[column] > a[column]) ? 1 : -1);
            });
          }
        }
      }
    }
    return array;
  }

  checkVendorAdmin() {
    this.router.events.subscribe((event) => {
      // You only receive NavigationStart events
      if (event instanceof NavigationEnd) {

        setTimeout(() => {
          this.forage.getItem('auth_token').then(res => {
            if (res) {
              this.headerUpdate$.next(true);
            } else {
              this.headerUpdate$.next(false);
            }
          });

          this.forage.getItem('isVendor').then(res => {
            if (res) {
              this.isVendorUpdate$.next(true);
            } else {
              this.isVendorUpdate$.next(false);
            }
          })

          this.forage.getItem('isAdmin').then(res => {
            if (res) {
              this.isAdminUpdate$.next(true);
            } else {
              this.isAdminUpdate$.next(false);
            }
          })
        }, 1000);
      }
    });


  }

}
// TABLE SORTING FUNCTION START

