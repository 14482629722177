import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LocalCacheService {
  constructor(
    private _localstorage: LocalStorageService,
    private _router: Router
  ) {}

  clearCache() {
    return this._localstorage.clearCache();
  }

  setItem(key, value) {
    return this._localstorage.setItem(key, value);
  }

  getItem(key) {
    return this._localstorage.getItem(key);
  }

  getMultipleItem(key: any) {
    return new Promise((resolve, reject) => {
      var data = [];
      key.forEach((el, index) => {
        var a = "{" + el + ":" + this._localstorage.getItem(el) + "}";
        data.push(a);
        if (index == key.length - 1) {
          resolve(data);
        }
      });
    });
  }

  updateItem(key, value) {
    return this.removeItem(key).then((res) => {
      return this._localstorage.setItem(key, value);
    });
  }

  removeItem(key) {
    return this._localstorage.removeItem(key);
  }

  initiateLogOut() {
    this._localstorage.clearCache();
    this._router.navigate([""]);
  }
}
