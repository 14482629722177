import { Injectable } from "@angular/core";
import { CommonServiceService } from "./common-service.service";

@Injectable({
  providedIn: "root",
})
export class ConstantsService {
  httpOptions(httpOptions: any, data: { activeVariant: any; id: any }) {
    throw new Error("Method not implemented.");
  }

  BOOKINGSUMMARY: any;

  constructor(public apiservice: CommonServiceService) {}

  // LOGIN = 'admin/login';
  Users = "admin/user";
  UsersID = "admin/userByid";
  spaceProvider = "admin/spaceProvider";
  spaceProviderID = "admin/spaceProviderByid";
  parkingSpots = "admin/parkingSpots";
  parkingSpotsID = "admin/parkingSpotsByid";
  ADMINLOGIN = "admin/login";
  ADMINBOOKING = "admin/booking";
  PARKINGSPOTADMIN = "admin/parkingspot";
  ADMINLOG = "admin/log";
  ADMINMANAGER = "admin/parkingmanager";
  DEACTIVATE = "admin/parkingmanager/deactivate";
  ADMINCOUNT = "admin/count";
  PARKINGSPACEADMIN = "admin/vendor";
  PRICE = "admin/pricing";
  NEWPRICE = "admin/add-pricing";
  PARKINGSPOTTYPE = "admin/parkingspottype";
  PRICINGCATEGORY = "admin/pricing-category";
  // GETPRICINGCATEGORYBYCATEGORY = "admin/pricing";
  ADMINPRICING = "admin/add-pricing";
  VEHICLETYPE = "admin/vehicle-type";
  VENDORVEHICLETYPE = "vendor/vehicle-type";
  COMPANY = "admin/company";
  VENDORCOMPANY = "vendor/company";

  //ADMIN VENDOR API START HERE
  SIGNUP = "vendor/signup";
  VENDORLOGIN = "vendor/login";
  PARKINGSPOT = "vendor/parkingspot";
  BOOKING = "vendor/booking";
  //UPDATEBOOKING = 'vendor/booking/';
  PARKINGLOG = "vendor/parking-log";
  PARKINGMANAGER = "vendor/parking-manager";
  COUNT = "vendor/count";
  LOGEXCEL = "vendor/parking-log-excel-export";
  PASSWORD_UPDATE = "vendor/updatePassword";
  PRICINGCATEGORY1 = "vendor/pricing-category";
  PARKINGSPOTTYPE1 = "vendor/parkingspottype";
  GETPRICINGCATEGORYBYCATEGORY1 = "vendor/pricing";

  //RESIDENT VENDOR
  GUESTPARKINGLOG = "vendor/residential/log";
  GATE = "vendor/residential/gate";
  PURPOSE = "vendor/residential/purpose";
  RFID = "vendor/residential/rfidpass";
  ALLOCATEDPARKING = "vendor/residential/allocated-parking-area";

  ADMINALLOCATEPARKING = "admin/residential/allocated-parking-area";

  RESIDENTPARKINGLOG = "vendor/residential/log";
  ADMINGUESTPARKINGLOG = "admin/residential/log";
  ADMINRESIDENTPARKINGLOG = "admin/residential/log";
  RESIDENT = "vendor/residential/resident";
  ADMINRESIDENT = "admin/residential/resident";
  CAR = "vendor/residential/registered-vehicles";
  ADMINCAR = "admin/residential/registered-vehicles";

  RESIDENTWING = "vendor/residential/wing-flat";
  RESIDENTWINGDROPDOWN = "vendor/residential/wing";
  ADMINRESIDENTWING = "admin/residential/wing-flat";

  // vendor dashboard
  VENDOR_COMMON_DASHBOARD_RESIDENTIAL = "vendor/residential/count";
  ADMIN_COMMON_DASHBOARD_RESIDENTIAL = "admin/residential/count";

  ADMINUSERVEHICLES = "admin/user-vehicle";

  FOURWHEELERBRAND = "admin/wheeler-brand";
  FOURWHEELERTYPE = "admin/wheeler-type";

  PAYANDPARKALLUSERS = "admin/client";
  PAYANDPARKUSERSVECHICLES = "admin/user-vehicle";

  VEHICLESBRANDS = "admin/wheeler-brand";
  ADMINUSERS = 'admin';

  GSCLOG = 'vendor/club/log'
  GSC_DASHBOARD = 'vendor/club/count';
  GSC_CHECKIN_LOG = 'vendor/club/checkin-log';

  WEBSITECONTENT = 'admin/website/content'
  WEBSITECLIENT = 'admin/website/client'
  WEBSITESERVICE = 'admin/website/service'
  WEBSITESHOWCASE = 'admin/website/showcase'
  WEBSITEOURTEAM = 'admin/website/our-team'
  WEBSITECONTACT = 'admin/website/contact'
  WEBSITEBLOGS = 'admin/website/blog';
  WEBSITECATEGORIES = 'admin/website/blog-category';
  WEBSITETESTIMONIALS = 'admin/website/testimonial';

  VENDORREPORT = 'vendor/residential/log-count';
  ADMINREPORT = 'admin/residential/log-count';

  WEBSITEOURINSTALLATIONS = 'admin/website/our-installation';

  // OIS

  OISSTUDENT = 'vendor/ois/student';
  OISSTUDENTVEHICLE = 'vendor/ois/student/vehicle';
  OISSTUDENTVEHICLELOGS = 'vendor/ois/log';
  OISGATE = 'vendor/ois/gate';

  // OIS ADMIN

  OISADMINSTUDENT = 'admin/ois/student';
  OISADMINSTUDENTVEHICLE = 'admin/ois/student/vehicle';
  OISADMINSTUDENTVEHICLELOGS = 'admin/ois/log';
  OISADMINGATE = 'admin/ois/gate';

  VENDORFUELTYPE = 'vendor/fuel-type';
}
