import { Component, OnInit } from "@angular/core";
declare var $: any;
@Component({
  selector: "app-residential-vendor-sidebar",
  templateUrl: "./residential-vendor-sidebar.component.html",
  styleUrls: ["./residential-vendor-sidebar.component.scss"],
})
export class ResidentialVendorSidebarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  /* This function will close sidebar */

  close() {
    $("body").removeClass("sidebar-enable");
  }
}
