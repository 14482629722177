import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "src/app/core/services/cookie.service";
import { LocalCacheService } from "src/app/service/cache/local-cache.service";
import { CommonServiceService } from "src/app/service/common-service.service";
declare var $: any;

@Component({
  selector: "app-admin-topbar",
  templateUrl: "./admin-topbar.component.html",
  styleUrls: ["./admin-topbar.component.scss"],
})
export class AdminTopbarComponent implements OnInit {
  constructor(
    public commonService: CommonServiceService,
    public forage: LocalCacheService,
    public router: Router,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.forage.getItem("FullName_Admin").then((res) => {
      if (res) {
        this.commonService.FullName_Admin = res;
      } else {
        this.commonService.FullName_Admin = undefined;
      }
    });
  }

  /* This function will open sidebar */

  open() {
    if ($(window).width() < 767) {
      $("body").toggleClass("sidebar-enable");
    } else if ($(window).width() > 767) {
      $("body").toggleClass("enlarged");
    }
  }

  /* This function will logout */

  logout() {
    this.commonService.headerUpdate$.next(false);
    this.forage.clearCache();
    this.cookieService.deleteCookie("currentUser");
    this.router.navigate(["/admin-login"]);
  }
}
